<template>
  <v-container class="px-lg-10 mx-0">
    <v-row
      class="pt-8 mb-n5"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pl-5"
      >
        <v-text-field
          v-model="password.oldPassword"
          label="Old Password"
          color="purple"
          type="password"
          required
          outlined
          dense
          :error-messages="errOldPassword"
        />
      </v-col>
    </v-row>

    <v-row class="mb-n5">
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pl-5"
      >
        <v-text-field
          v-model="password.newPassword"
          label="New Password"
          color="purple"
          type="password"
          required
          outlined
          dense
          :error-messages="errNewPassword"
        />
      </v-col>
    </v-row>

    <v-row class="mb-n5">
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pl-5"
      >
        <v-text-field
          v-model="password.confirmPassword"
          label="Confirm New Password"
          color="purple"
          type="password"
          required
          outlined
          dense
          :error-messages="errConfirmPassword"
        />
      </v-col>
    </v-row>

    <v-row
      class="pt-2"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pl-5"
      >
        <v-btn
          color="purple"
          block
          @click="saveButtonClick"
        >
          SAVE
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      dataUser: [],
      password: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      errOldPassword: '',
      errNewPassword: '',
      errConfirmPassword: '',
    }),

    computed: {
      // passwordConfirmationRule () {
      //   return this.password.newPassword === this.password.confirmPassword || 'Password must match'
      // },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }
      },

      saveButtonClick () {
        if (!this.fieldValidation()) return

        const requestBody = {
          old_pass: this.password.oldPassword,
          new_pass: this.password.newPassword,
          new_pass_verif: this.password.confirmPassword,
        }

        axios.post('/t/profile/personal/changePwd', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.password.oldPassword = ''
            this.password.newPassword = ''
            this.password.confirmPassword = ''
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      fieldValidation () {
        let errCount = 0

        if (this.password.oldPassword.trim().length === 0) {
          this.errOldPassword = 'Password lama harus diisi'
          errCount++
        } else {
          this.errOldPassword = ''
        }

        if (this.password.newPassword.trim().length === 0) {
          this.errNewPassword = 'Password baru harus diisi'
          errCount++
        } else {
          this.errNewPassword = ''
        }

        if (this.password.confirmPassword.trim().length === 0) {
          this.errConfirmPassword = 'Konfirmasi password harus diisi'
          errCount++
        } else if (this.password.newPassword !== this.password.confirmPassword) {
          this.errConfirmPassword = 'Konfirmasi password yang anda masukan tidak sama'
          errCount++
        } else {
          this.errConfirmPassword = ''
        }

        if (errCount > 0) return false

        return true
      },
    },
  }
</script>
